/**
 * Хелперы для работы с урлами на проекте
 *
 * Доступ в коде: req.lib('urlBuilder')
 * bh: bh.lib.urlBuilder
 */
const moment = require('moment');
const _ = {
    escape: require('lodash/escape'),
    flatten: require('lodash/flatten'),
    get: require('lodash/get'),
    uniq: require('lodash/uniq'),
};
const {IS_QA} = require('app/common/libs/env');
const {PAGES} = require('app/common/libs/regexp');
const RegionModel = require('app/common/models/region');
const {regexp: SUPPORTED_TLDS} = require('app/common/constants/tlds');
const {APP} = require('app/common/libs/config');

const REGEXP_POSITION_MATCH_ID = 4;

const LANG_PARAM = 'lang';
const SERP_APP_PARAM = 'appsearch_header';
const KINOPOISK_IFARME_PARAM = 'kinopoisk_channel';

/**
 * Удаляем все ключи с пустыми значениями и энкодим значения получившегося объекта
 * @param {Object} params
 * @return {Object}
 * @private
 */
function clearParams(params) {
    let value;
    params = Object.assign({}, params);

    // Параметр из tune
    delete params.ncrnd;

    Object.keys(params).forEach(key => {
        value = params[key];

        if ((Array.isArray(value) && !value.length) || (!value && value !== 0)) {
            delete params[key];
        } else {
            if (Array.isArray(params[key])) {
                params[key] = params[key].map(_.escape);
            } else {
                params[key] = _.escape(params[key]);
            }
        }
    });

    return params;
}

module.exports = {

    /**
     * Очищаем pathname урла
     * @param {String|Array} [url] грязная строка с урлом
     * @returns {String} чистая строка. Начинается со `/`, не имеет двойных `/` и пустых значений
     */
    clearUrl(url) {
        url = url
            ? Array.isArray(url) ? url : String(url).split('/')
            : [];
        url = url.filter(Boolean);

        url = url.join('/').replace(/\/+/gi, '/').replace('/?', '?').replace(/\/$/, '');

        return `/${url}`.replace(/\/+/gi, '/');
    },

    /**
     * Строим урл по региону
     * @param {Number|String|String[]|null} [pathname] pathname
     * @param {Object|null} [params] параметры в query
     * @param {Number|Boolean|null} [regionId] номер региона.
     * @param {String} [tld] ru|ua|by|kz|uz Если указан, строим полный урл
     * @returns {String} очищенный урл с регионом
     */
    buildUrl(pathname, params, regionId, tld) {
        pathname = pathname ? this.clearUrl(pathname) : '';
        params = Object.assign({}, params);

        if (!regionId && regionId !== null && !this.isPortalRegionId) {
            regionId = _.get(this.req, 'region.id') || _.get(this.req, 'regionId');
        }

        const url = this.clearUrl([regionId, pathname, this.buildQuery(params)]);

        return tld ? `${this.buildHostnameWithTld(tld)}${url}` : url;
    },

    /**
     * Строим hostname с TLD
     * @param {String} tld ru|ua|by|kz|uz
     * @returns {String}
     */
    buildHostnameWithTld(tld) {
        return `https://${this.req.appHost.replace(SUPPORTED_TLDS, tld)}`;
    },

    /**
     * Строим `query` из параметров
     * @param {Object} [params]
     * @param {Boolean} [toClearParams = true] очищаем и энкодим параметры?
     * @return {String} `?param1=value1...`
     */
    buildQuery(params, toClearParams) {
        params = this.completeParams(params);
        toClearParams = toClearParams === undefined ? true : toClearParams;
        params = toClearParams ? clearParams(params) : params;

        var query = [];
        var value;

        Object.keys(params).sort().forEach(key => {
            value = params[key];

            if (Array.isArray(value)) {
                Object.keys(value).sort().forEach(part => {
                    query.push(`${key}=${value[part]}`);
                });
            } else {
                query.push(`${key}=${value}`);
            }
        });

        return query.length ? `?${query.join('&')}` : '';
    },

    /**
     * Если в query уже есть параметры, доабавляем их
     * @param {Object} [params]
     * @returns {Object}
     */
    completeParams(params = {}) {
        return [LANG_PARAM, SERP_APP_PARAM].reduce((result, key) => {
            let param = params[key] || this.req.query[key];

            param = param ? {
                [key]: param,
            } : {};

            Object.assign(result, param, params);

            return result;
        }, {});
    },

    /**
     * Строим урл главной страницы
     * @param {Object|null} [params] параметры в query
     * @param {Number} [regionId] номер региона
     * @returns {String} очищеный урл главной страницы
     */
    getIndexUrl(params, regionId) {
        params = this.buildIndexParams(params);

        return this.buildUrl(null, params, regionId);
    },

    /**
     * Строим параметры для главной страницы/страницы канала
     * Получаем все из адресной строки и накладываем пришедшие в аргументах
     * @param {Object} [params] параметры в query
     * @returns {Object}
     */
    buildIndexParams(params) {
        const originalParams = {
            date: this.req.query.date,
            genre: this.req.query.genre,
            grid: this.req.query.grid,
            period: this.req.query.period,
        };

        params = Object.assign(originalParams, params);

        // `programTypes` может быть несколько, но все они должны быть уникальными
        if (params.genre) {
            params.genre = _.uniq(_.flatten([params.genre]).filter(Boolean).sort());
        }

        return params;
    },

    /**
     * Строим query из объекта параметров для главной страницы/страницы канала
     * @param {Object|null} [params] параметры в query
     * @param {String|Object|Date|Array} [params.date] параметр даты в query
     * @returns {String}
     */
    buildIndexQuery(params) {
        if (params && params.date) {
            params.date = moment(params.date).format('YYYY-MM-DD');
        }

        if (params && params.isViewInIframeKinopoiskhd) {
            params[KINOPOISK_IFARME_PARAM] = '1';
        }

        return this.buildQuery(this.buildIndexParams(params));
    },

    /**
     * Получаем TLD
     * @returns {String}
     */
    getTld() {
        const hostname = this.req.appHost;
        const tldMatch = hostname.match(SUPPORTED_TLDS);

        if (tldMatch) {
            return tldMatch[0];
        }

        throw new Error(`Unsupported host '${hostname}'`);
    },

    /**
     * Получаем id региона
     * @returns {number|null}
     */
    getRegionId() {
        const pathRegionId = _.get(this.req, 'regionId', null);

        return pathRegionId ? RegionModel.normalizeId(pathRegionId) : null;
    },

    /**
     * Строим параметр retpath для текущего урла
     * @returns {Object}
     */
    buildRetpathParam() {
        return {
            retpath: encodeURIComponent(this.req.originalUrl),
        };
    },

    /**
     * Получаем retpath из query
     * @returns {String}
     */
    getRetpathUrl() {
        return this.req.query.retpath || '/';
    },

    /**
     * Получаем id региона из retpath
     * @returns {Number}
     */
    getRetpathRegionId() {
        const regionId = this.getRetpathUrl().match(/\d+/);

        if (regionId) {
            return Number(regionId[0]);
        }
    },

    /**
     * Получаем id региона из url
     * @param {String} [path]
     * @returns {Number}
     */
    getPathRegionId(path) {
        path = path || this.req.path || '';
        const regionId = path.match(/^\/(\d+)/);

        return regionId ? Number(regionId[1]) : 0;
    },

    /**
     * Получаем id региона из headers.referer
     * @returns {Number}
     */
    get refererRegionId() {
        const referer = this.req.headers.referer;

        if (referer) {
            const regionId = referer.match(/\d+/);

            if (regionId) {
                return Number(regionId[0]);
            }
        }
    },

    /**
     * Строим урлы на все версии приложеньки
     * @returns {Object} ({pda: String, touch: String, desktop: String})
     */
    get appUrls() {
        const baseUrl = this.req.appHost.replace(
            /^(?:www.)?(new.)?((?:pda|m|\d+)\.)?(tvqa|local|dev|unstable|stress|testing|beta|tv)(-(desktop|touch|pda))?/,
            (full, isNew, type, env) => IS_QA
                ? `${type}${env}{version}`
                : `${isNew || ''}{version}${env}`
        );

        let versions = {
            pda: IS_QA ? '-pda' : 'pda.',
            www: IS_QA ? '-desktop' : '',
        };

        Object.keys(versions).forEach(name => {
            const hostname = baseUrl
                .replace('{version}', versions[name])
                .replace('www.', ''); // Для случая, когда открываем www.new.testing.tv.yandex.ru
            let path = this.req.path.replace(/\/+$/, '');
            const query = this.buildQuery(this.req.query);

            if (name === 'pda') {
                if (this.isChannelUrl) {
                    path = this._replaceFriendlyURL(path, PAGES.CHANNEL);
                }

                if (this.isProgramUrl) {
                    path = this._replaceFriendlyURL(path, PAGES.PROGRAM);
                }
            }

            versions[name] = `https://${hostname}${path}${query}`;
        });

        return versions;
    },

    /**
     * pda и touch не поддерживают ЧПУ
     * @param {String} path
     * @param {Object} regExp
     * @returns {String}
     * @private
     */
    _replaceFriendlyURL(path, regExp) {
        return path.replace(regExp, '$1$4');
    },

    /**
     * Строим урл страницы пользователя
     * @returns {String} очищеный урл страницы пользователя
     */
    getUserUrl() {
        const params = this.buildRetpathParam();

        return this.clearUrl(`user${this.buildQuery(params)}`);
    },

    /**
     * Строим урл страницы канала
     * @param {Number|String} [channelId] id канала
     * @param {Number|Boolean|null} [regionId] номер региона
     * @param {Object|null} [params] параметры в query
     * @returns {String} очищеный урл страницы канала
     */
    getChannelUrl(channelId, regionId, params) {
        channelId = channelId || '';
        return this.buildUrl(`channel/${channelId}`, params, regionId);
    },

    /**
     * Строим урл страницы канала
     * @param {Number|String} [channelId] id канала
     * @param {Number|Boolean|null} [regionId] номер региона
     * @param {Object|null} [params] параметры в query
     * @returns {String} очищеный урл страницы канала
     */
    getChannelOldUrl(channelId, regionId, params) {
        channelId = channelId || '';
        return this.buildUrl(`channels/${channelId}`, params, regionId);
    },

    /**
     * Строим урл страницы программы
     * @param {Number} programId
     * @param {Number|null} [regionId] номер региона
     * @returns {String}
     */
    getProgramUrl(programId, regionId) {
        return this.buildUrl(`program/${programId}`, null, regionId);
    },

    /**
     * Строим урл страницы события
     * @param {Number} programId id программы
     * @param {Number} eventId id события
     * @param {Number} [regionId] номер региона
     * @returns {String} очищеный урл страницы события
     */
    getEventUrl(programId, eventId, regionId) {
        return this.buildUrl(`program/${programId}`, {eventId: eventId}, regionId);
    },

    /**
     * Строим урл страницы настройки каналов
     * @param {Number} [regionId] номер региона
     * @returns {String} очищеный урл страницы настройки каналов
     */
    getTuneUrl(regionId) {
        return this.buildUrl('tune', null, regionId);
    },

    /**
     * Строим урл страницы поиска
     * @param {Object} [params] параметры
     * @param {Number} [regionId] номер региона
     * @returns {String} очищеный урл страницы поиска
     */
    getSearchUrl(params, regionId) {
        if (params && params.text) {
            const percentReplace = encodeURIComponent('%');

            params.text = encodeURIComponent(
                decodeURIComponent(params.text.replace(/%/gi, percentReplace))
            );
        }

        return this.buildUrl('search', params, regionId);
    },

    /**
     * Страница ХП
     * @param {Number} [regionId] номер региона
     * @returns {String}
     */
    getWannaSeeUrl(regionId) {
        return this.buildUrl('my/favorites', null, regionId);
    },

    /**
     * Ссылка на эфир
     * @param {Number} [channelId=undefined]
     * @param {Number} [familyId=undefined]
     * @param {Number} [regionId=undefined]
     * @return {String}
     */
    getStreamUrl(channelId, familyId, regionId) {
        const channelUrl = channelId
            ? `${this.getChannelUrl(channelId, regionId)}/stream`
            : this.getChannelUrl('stream', regionId);

        return !familyId ? channelUrl : `${channelUrl}?familyId=${familyId}`;
    },

    /**
     * Ссылка на плеер
     * @param {Number} [channelId=undefined]
     * @param {Number} [regionId=undefined]
     * @return {String}
     */
    getPlayerUrl(channelId, regionId) {
        return channelId
            ? `${this.getChannelUrl(channelId, regionId)}/player`
            : this.getChannelUrl('player', regionId);
    },

    /**
     * Страница спец. проекта
     * @param {String} path путь
     * @param {Number} [regionId] номер региона
     * @returns {String}
     */
    getProjectUrl(path, regionId) {
        return this.buildUrl(path, null, regionId);
    },

    /**
     * Страница регулярного спец. проекта
     * @param {String} path путь
     * @param {Number} [regionId] номер региона
     * @returns {String}
     */
    getRegularProjectUrl(path, regionId) {
        return this.buildUrl(`lists/${path}`, null, regionId);
    },

    /**
     * Страница спец. проекта для программы
     * @param {Number} [programId]
     * @param {String} [path]
     * @returns {String}
     */
    getSpecialProgramUrl(programId, path) {
        if (!path) {
            const specials = _.get(this.req, 'entryPoints.programs', []);
            path = _.get(specials.filter(special => (
                special.programIds.includes(programId)
            )), '0.url');
        }

        return path && this.buildUrl(`special/${path}`, null, null);
    },

    /**
     * @param {String} pathname
     * @returns {Number|null}
     */
    getProgramId(pathname = this.req.path) {
        const programId = pathname.match(PAGES.PROGRAM);

        return programId ? Number(programId[REGEXP_POSITION_MATCH_ID]) : null;
    },

    /**
     * @param {String} pathname
     * @returns {Number|null}
     */
    getChannelId(pathname = this.req.path) {
        const channelId = pathname.match(PAGES.CHANNEL);

        return channelId ? Number(channelId[REGEXP_POSITION_MATCH_ID]) : null;
    },

    /**
     * Главная страница
     * @returns {Boolean}
     */
    get isIndexUrl() {
        return PAGES.INDEX.test(this.req.path);
    },

    /**
     * Cтраница канала с family ID
     * @returns {Boolean}
     */
    get isChannelUrl() {
        return PAGES.CHANNEL.test(this.req.path);
    },

    /**
     * Cтраница канала с channel ID
     * @returns {Boolean}
     */
    get isChannelOldUrl() {
        return this.isPda || PAGES.CHANNEL_OLD.test(this.req.path);
    },

    /**
     * Cтраница эфира
     * @returns {Boolean}
     */
    get isStreamUrl() {
        return PAGES.STREAM.test(this.req.path);
    },

    /**
     * Cтраница канала с эфиром с channel ID
     * @returns {Boolean}
     */
    get isStreamOldUrl() {
        return PAGES.STREAM_OLD.test(this.req.path);
    },

    /**
     * Cтраница программы
     * @returns {Boolean}
     */
    get isProgramUrl() {
        return PAGES.PROGRAM.test(this.req.path);
    },

    /**
     * Cтраница ХП
     * @returns {Boolean}
     */
    get isFavoritesUrl() {
        return PAGES.FAVORITES.test(this.req.path);
    },

    /**
     * Cтраница подборок
     * @returns {Boolean}
     */
    get isListsUrl() {
        return PAGES.LISTS.test(this.req.path);
    },

    /**
     * Cтраница результатов поиска
     * @returns {Boolean}
     */
    get isSearchUrl() {
        return PAGES.SEARCH.test(this.req.path);
    },

    /**
     * Cтраница спец. проекта
     * @returns {Boolean}
     */
    get isSpecialUrl() {
        return PAGES.SPECIAL.test(this.req.path);
    },

    /**
     * Cтраница спец. проекта sport
     * @returns {Boolean}
     */
    get isSpecialSportUrl() {
        return PAGES.SPORT.test(this.req.path);
    },

    /**
     * Cтраница онлайн просмотра фильмов
     * @returns {Boolean}
     */
    get isOnlineUrl() {
        return PAGES.ONLINE.test(this.req.path);
    },

    /**
     * @returns {Boolean}
     */
    get isDesktop() {
        return !this.isPda;
    },

    /**
     * @returns {Boolean}
     */
    get isPda() {
        return (this.req.headers['x-upstream-host'] || '').startsWith('pda.') || APP === 'pda';
    },

    /**
     * Получаем grid param или пустую строку
     * @return {string}
     */
    get getGridParam() {
        return this.req.query.grid || '';
    },

    /**
     * Проверяем является ли регион в урле портальным
     * @returns {Boolean}
     */
    get isPortalRegionId() {
        const {regionIdForMeta, portalId} = this.req.region;

        return !regionIdForMeta || regionIdForMeta === portalId;
    },

    /**
     * Есть ли query параметр поискового приложения для конкретных страниц
     * @returns {Boolean}
     */
    get isSerpApp() {
        return (this.isIndexUrl || this.isProgramUrl || this.isChannelUrl) && Boolean(this.req.query[SERP_APP_PARAM]);
    },

    /**
     * @returns {Boolean}
     */
    get isViewInIframeKinopoiskhd() {
        return this.isChannelUrl && Boolean(this.req.query[KINOPOISK_IFARME_PARAM]);
    },
};
