require('./GridChunk.scss');

const b = require('app/www/libs/b')('grid-chunk');

const React = require('react');
const PropTypes = require('prop-types');

const {getPeriod} = require('app/common/libs/periods');

const Ua = require('app/www/components/blocks/Ua/Ua');
const Global = require('app/www/components/blocks/Global/Global');
const AdvManager = require('app/www/components/blocks/AdvManager/AdvManager');
const GridItem = require('app/www/components/blocks/GridItem/GridItem');

const MIN_COUNT_ITEMS_FOR_SHOW_ADV_DESKTOP_NOW = 21;
const MIN_COUNT_ITEMS_FOR_SHOW_ADV_DESKTOP = 11;
const COUNT_ITEMS_IN_CHUNK_PHONE = 10;
const MIN_COUNT_ITEMS_FOR_SHOW_ADV_PHONE = 5;
const MIN_EVENTS_AND_ITEMS_FOR_ADV = 3;
const COUNT_ITEMS_FOR_LITTLE_ADV = 7;
const ADV_STAT_ID = 3;
let chunkIdAdv;

const GridChunk = (props) => {
    const {
        chunk,
        chunkId,
        balloon,
        messages,
        totalPages,
        selectedDate,
        selectedPeriod,
        onChangeFavorite,
        comboChunkOptions,
        isLayoutMainLarge,
        selectedProgramTypes,
    } = props;

    const {
        advColumn,
        chunkIds,
        length: comboChunkLenght,
        indexBeforeBlank,
    } = comboChunkOptions;

    Object.assign(advColumn.props, {
        statId: ADV_STAT_ID,
    });

    const isNow = selectedPeriod === getPeriod('now').alias;
    let countShowAdv = isNow
        ? MIN_COUNT_ITEMS_FOR_SHOW_ADV_DESKTOP_NOW
        : MIN_COUNT_ITEMS_FOR_SHOW_ADV_DESKTOP;

    if (Ua.isTouchPhone) {
        countShowAdv = MIN_COUNT_ITEMS_FOR_SHOW_ADV_PHONE;
    }

    const isFirst = chunkId === 0;
    const hasQuiteEventsForADV = !chunk
        .slice(0, MIN_EVENTS_AND_ITEMS_FOR_ADV)
        .every(schedule => schedule.events.length <= MIN_EVENTS_AND_ITEMS_FOR_ADV);

    const isComboChunk = chunkIds.includes(chunkId) && hasQuiteEventsForADV;
    let restItems = [];
    const isLastPage = (chunkId + 1) === totalPages;
    const isAdvShow = countShowAdv <= chunk.length && hasQuiteEventsForADV && !isLastPage && !Ua.isRobot;

    if (COUNT_ITEMS_FOR_LITTLE_ADV > chunk.length) {
        advColumn.props.blockId = Global.adv['desktop_grid_cell_little'];
    }

    const items = chunk.reduce((schedules, schedule, index) => {
        index = index + 1;

        chunkIdAdv = chunkId;

        if (Ua.isTouchPhone) {
            chunkIdAdv = chunkId * COUNT_ITEMS_IN_CHUNK_PHONE + index;
        }

        const isAdvWideShow = isAdvShow && index % countShowAdv === 0;
        const hasAnyMatchEvent = schedule.events.filter(item => selectedProgramTypes.includes(item.program.type.alias)).length > 0;

        const gridItemProps = Object.assign({
            channel: Object.assign({}, schedule.channel, {
                onChangeFavorite,
                hasAnyMatchEvent,
            }),
            events: {
                events: schedule.events,
                selectedProgramTypes,
                hasFinished: schedule.hasFinished,
                messages,
            },
            mods: {
                'before-blank': isComboChunk && index === indexBeforeBlank && !isLayoutMainLarge,
            },
            isNow,
            selectedDate,
        }, balloon && {
            onMouseEnter: balloon.onMouseEnterEvent,
            onMouseLeave: balloon.onMouseLeaveEvent,
            onClick: balloon.onClickEvent,
        });

        const gridItem = <GridItem key={`${schedule.channel.id}_item`} {...gridItemProps} />;

        if (isComboChunk) {
            if (!Ua.isTouchPhone || !selectedProgramTypes.length || hasAnyMatchEvent) {
                const isFirstEvent = Ua.isTouchPhone && isFirst && index === 1;

                const params = {
                    index,
                    isAdvWideShow,
                    comboChunkOptions,
                    isFirstEvent,
                };

                restItems.push(gridItem, _renderAdvWide(params));
            }

            const content = [restItems];

            if (!Ua.isTouchPhone) {
                const advSideBlock = (
                    <div key='column-box' className={b('column-box', {'fixed-height': chunk.length < comboChunkLenght})}>
                        <div key="adv_column" className={b('adv-column')}>
                            <AdvManager {...advColumn.props} />
                        </div>
                    </div>
                );

                content.push(advSideBlock);
            }
            return content;
        }

        if (!Ua.isTouchPhone || !selectedProgramTypes.length || hasAnyMatchEvent) {
            const params = {
                index,
                isAdvWideShow,
                comboChunkOptions,
            };
            schedules.push(gridItem, _renderAdvWide(params));
        }

        return schedules;

    }, []);

    const mods = {
        'no-flexwrap': !_hasFlexwrapSupport(),
        'no-grid': !_hasGridSupport(),
        'is-top': isFirst,
        'is-now': isFirst && isNow,
    };

    return (
        <div className={b(mods)}>
            {items}
        </div>
    );
};

/**
 * @private
 */

function _hasFlexwrapSupport() {
    return Global.isClient && window.Modernizr.flexwrap;
}

/**
 * @private
 */

function _hasGridSupport() {
    return Global.isClient && window.Modernizr.cssgrid;
}

/**
 * @param {Number} index
 * @param {Boolean} isAdvWideShow
 * @param {Object} params
 * @private
 */

function _renderAdvWide(params) {
    const {index, isAdvWideShow, comboChunkOptions, isFirstEvent} = params;
    const {advWide} = comboChunkOptions;

    Object.assign(advWide.props, {
        index: chunkIdAdv,
        statId: ADV_STAT_ID,
    });

    if (isAdvWideShow || isFirstEvent) {
        return (
            <div key={`adv_wide_${chunkIdAdv}_${index}`} className={b('adv-wide')}>
                <AdvManager {...advWide.props} />
            </div>
        );
    }
}

GridChunk.propTypes = {
    chunk: PropTypes.array.isRequired,
    chunkId: PropTypes.number.isRequired,
    totalPages: PropTypes.number,
    comboChunkOptions: PropTypes.shape({
        chunkIds: PropTypes.array,
        length: PropTypes.number,
        indexBeforeBlank: PropTypes.number,
        advWide: PropTypes.object,
        advColumn: PropTypes.object,
    }),
    onChangeFavorite: PropTypes.func,
    getChunksByProgramType: PropTypes.func,
};

module.exports = GridChunk;
