const _ = {
    pickBy: require('lodash/pickBy'),
};
const serializeJavascript = require('serialize-javascript');

const React = require('react');
const PropTypes = require('prop-types');

class Global extends React.PureComponent {

    constructor(props) {
        super(props);

        Global._source = this.props;
    }

    render() {
        const inlineLegoGlobal = {
            __html: `window.LEGO_GLOBAL = ${serializeJavascript(Global._source, { isJSON: true })};`,
        };
        const props = _.pickBy({
            dangerouslySetInnerHTML: inlineLegoGlobal,
            nonce: Global.nonce,
        }, Boolean);

        return (
            <script {...props} />
        );
    }

    /**
     * Идентификатор сервиса
     * @returns {String}
     */
    static get id() {
        return Global._source.id;
    }

    /**
     * Идентификатор языка
     * @returns {String}
     */
    static get lang() {
        return Global._source.lang;
    }

    /**
     * Хост саджеста
     * @returns {String}
     */
    static get suggestHost() {
        return Global._source.suggestHost;
    }

    /**
     * Название сервиса
     * yandexuid
     * @returns {String}
     */
    static get yandexuid() {
        return Global._source.yandexuid;
    }

    /**
     * Сrc пользователя
     * @returns {String}
     */
    static get crc() {
        return Global._source.crc;
    }

    /**
     * Домен верхнего уровня
     * @returns {String}
     */
    static get tld() {
        let tld = Global._source.tld;

        if (!tld && this.isClient) {
            tld = document.location.hostname.match(/\.(\w+)$/)[1];
        }

        return tld;
    }

    /**
     * Урл главной Яндекса
     * @returns {String}
     */
    static get yandexUrl() {
        return `//yandex.${Global.tld}`;
    }

    /**
     * Новый урл главной Яндекса
     * @returns {String}
     */
    static get newYandexUrl() {
        return '//ya.ru';
    }

    /**
     * Урл паспорта
     * @returns {String}
     */
    static get passportUrl() {
        return `//${Global._addTld(Global._source.passportHost)}`;
    }

    /**
     * Урл настроек
     * Зависит от домена верхнего уровня
     * @returns {String}
     */
    static get tuneUrl() {
        return `//${Global._addTld(Global._source.tuneHost)}`;
    }

    /**
     * Урл аватаров
     * @returns {String}
     */
    static get avatarsHost() {
        return `//${Global._source.avatarsHost}`;
    }

    /**
     * Хост sms
     * @returns {String}
     */
    static get phoneHost() {
        return Global._source.phoneHost;
    }

    /**
     * Хост lang
     * @returns {String}
     */
    static get langHost() {
        return `//${Global._source.langHost}`;
    }

    /**
     * Куда перенаправлять пользователя
     * @returns {String}
     */
    static get retpath() {
        let retpath = Global._source.retpath;

        if (this.isClient) {
            retpath = encodeURIComponent(document.location.href);
        }

        return retpath;
    }

    /**
     * Данные для метрики
     * @returns {Object}
     */
    static get metrika() {
        return Global._source.metrika || null;
    }

    static get nonce() {
        return Global._source.nonce || null;
    }

    static get mailApi() {
        return Global._source.mailApi || null;
    }

    /**
     * Определяем клиента
     * @returns {Boolean}
     */
    static get isClient() {
        return typeof window !== 'undefined';
    }

    /**
     * Задаем урлу домен верхнего уровня
     * @param {String} url
     * @returns {String}
     */
    static _addTld(url) {
        return url.replace('{tld}', Global.tld);
    }

    /**
     * Id региона
     * @returns {Number}
     */
    static get regionId() {
        return Global._source.regionId;
    }

    /**
     * Название региона
     * @returns {String}
     */
    static get regionName() {
        return Global._source.regionName;
    }

    /**
     * Название региона в предложном падеже
     * @returns {String}
     */
    static get regionPrepositional() {
        return Global._source.regionPrepositional;
    }

    /**
     * Информация о регионе
     * @returns {object}
     */
    static get regionInfo() {
        return Global._source.regionInfo;
    }

    /**
     * регион Украины
     * @returns {Boolean}
     */
    static get isUkraine() {
        return Global._source.regionId === 187 || Global._source.portalId === 187;
    }

    /**
     * регионы России
     * @returns {Boolean}
     */
    static get isRussia() {
        return Global._source.countryId === 225;
    }

    /**
     * Москва
     * @returns {Boolean}
     */
    static get isMoscow() {
        return Global._source.regionId === 213 || Global._source.portalId === 213;
    }

    /**
     * Пользователь
     * @returns {Object}
     */
    static get user() {
        return Global._source.user;
    }

    /**
     * Провайдер
     * @returns {Object}
     */
    static get provider() {
        return Global._source.provider;
    }

    /**
     * map blockid Директа
     * @returns {Object}
     */
    static get adv() {
        return Global._source.adv;
    }

    /**
     * map adFox
     * @returns {Object}
     */
    static get adFox() {
        return Global._source.adFox;
    }

    /**
     * retpath без Id региона
     * @returns {String}
     */
    static get retpathWithoutRegionId() {
        const regionId = encodeURIComponent(`/${Global.regionId}`);
        return Global.retpath.replace(regionId, '');
    }

    /**
     * Урл настроек
     * @returns {string}
     */
    static get getTuneUrl() {
        return `${Global.tuneUrl}/?retpath=${Global.retpathWithoutRegionId}`;
    }

    /**
     * Урл страницы смены региона
     * @returns {String}
     */
    static get changeRegionUrl() {
        return `${Global.tuneUrl}/geo?retpath=${Global.retpathWithoutRegionId}`;
    }

    /**
     * Параметры для общего счетчика метрики
     * @returns {Object}
     */
    static get metrikaCommon() {
        return Global._source.metrikaCommon;
    }

    /**
     * Урл страницы поддержки
     * @returns {String}
     */
    static get supportUrl() {
        const tld = Global.tld === 'ua' ? 'ua' : 'ru';

        return `//yandex.${tld}/support/tv`;
    }

    /**
     * Урл обратной связи
     * @returns {String}
     */
    static get feedbackUrl() {
        return `${Global.supportUrl}/troubleshooting.html`;
    }

    /**
     * Урл страницы рекламы
     * @returns {String}
     */
    static get advertiseUrl() {
        return '//yandex.ru/adv/products/display/tv';
    }

    /**
     * Урл страницы пользовательского соглашения
     * @returns {String}
     */
    static get termsofuseUrl() {
        return `${Global.yandexUrl}/legal/tv_termsofuse`;
    }

    /**
     * Урл Кинопоиска
     * @returns {String}
     */
    static get kinopoiskUrl() {
        return '//www.kinopoisk.ru';
    }

    /**
     * Урл Кинопоиска в футере
     * @returns {String}
     */
    static get kinopoiskFooterUrl() {
        return `${Global.kinopoiskUrl}?utm_source=tv&utm_medium=footer`;
    }

    /**
     * Урл фильма на Кинопоиске
     * @param {Number} programCoId
     * @returns {String}
     */
    static kinopoiskProgramUrl(programCoId) {
        return `${Global.kinopoiskUrl}/film/${programCoId}`;
    }

    /**
     * Урл наград фильма на Кинопоиске
     * @param {Number} programCoId
     * @returns {String}
     */
    static kinopoiskProgramAwardsUrl(programCoId) {
        return `${Global.kinopoiskProgramUrl(programCoId)}/awards`;
    }

    /**
     * Урл рецензий фильма на Кинопоиске
     * @param {Number} programCoId
     * @returns {String}
     */
    static kinopoiskProgramReviewsUrl(programCoId) {
        return `${Global.kinopoiskProgramUrl(programCoId)}/reviews`;
    }

    /**
     * Урл персон на Кинопоиске
     * @param {Number} programCoId
     * @returns {String}
     */
    static kinopoiskProgramPersonsUrl(programCoId) {
        return `${Global.kinopoiskProgramUrl(programCoId)}/cast`;
    }

    /**
     * Урл персоны на Кинопоиске
     * @param {Number} personId
     * @returns {String}
     */
    static kinopoiskProgramPersonUrl(personId) {
        return `${Global.kinopoiskUrl}/name/${personId}`;
    }

    /**
     * Урл постов на Кинопоиске
     * @param {Number} programCoId
     * @returns {String}
     */
    static kinopoiskPostsUrl(programCoId) {
        return `${Global.kinopoiskUrl}/article/film/${programCoId}`;
    }

    /**
     * Урл поста на Кинопоиске
     * @param {Number} postId
     * @returns {String}
     */
    static kinopoiskPostUrl(postId) {
        return `${Global.kinopoiskUrl}/article/${postId}`;
    }

    /**
     * Урл цитат на Кинопоиске
     * @param {Number} programCoId
     * @returns {String}
     */
    static kinopoiskQuotesUrl(programCoId) {
        return `${Global.kinopoiskProgramUrl(programCoId)}/press`;
    }

    /**
     * Параметр use_test для саджеста
     * При use_test=1 ссылки ведут на testing.tv.yandex.ru
     * @returns {1|undefined}
     */
    static get suggestUseTest() {
        return Global._source.suggestUseTest;
    }

    /**
     * Хост OTT-виджета
     * @returns {String}
     */
    static get ottWidgetHost() {
        return Global._source.ottWidgetHost;
    }
}

Global.defaultProps = Global.isClient ?
    window.LEGO_GLOBAL : {
        id: '',
        lang: 'ru',
        tld: '',
        passportHost: 'passport.yandex.{tld}',
        tuneHost: 'yandex.{tld}/tune',
        avatarsHost: 'avatars.mds.yandex.net',
        retpath: '',
        mailApi: '',
    };

Global.propTypes = {
    id: PropTypes.string,
    lang: PropTypes.string,
    yandexuid: PropTypes.string,
    tld: PropTypes.string,
    passportHost: PropTypes.string,
    tuneHost: PropTypes.string,
    suggestHost: PropTypes.string,
    phoneHost: PropTypes.string,
    avatarsHost: PropTypes.string,
    langHost: PropTypes.string,
    retpath: PropTypes.string,
    crc: PropTypes.string,
    mailApi: PropTypes.string,
    regionName: PropTypes.string.isRequired,
    regionPrepositional: PropTypes.string,
    user: PropTypes.object.isRequired,
    provider: PropTypes.object,
    adv: PropTypes.object,
};

module.exports = Global;
